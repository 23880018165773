import React, { FC, lazy, ReactNode, Suspense } from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import { AppRoute, getRouteWithSlash } from "../../AppRoute";

import PrivateRoute from "../PrivateRoute";
import CandidatesFilterContextProvider from "@/common/components/CandidatesFilter/components/CandidatesFilterContextProvider";
import { getLocalitiesPaths } from "@/common/utils/navUtils";
import { Role } from "@/generated/types";
import VacanciesFilterContextProvider from "@/common/components/VacanciesFilter/components/VacanciesFilterContextProvider";
import {
  HomeDesktopLoader,
  HomePhoneLoader,
} from "@/common/components/loaders/Home";
import {
  VacanciesDesktopLoader,
  VacanciesPhoneLoader,
} from "@/common/components/loaders/Vacancies";
import {
  CandidatesDesktopLoader,
  CandidatesPhoneLoader,
} from "@/common/components/loaders/Candidates";
import { useIsTablet } from "@/common/hooks/useIsTablet";
import PageLoader from "@/common/components/loaders/PageLoader";
import CompaniesGridFilterContextProvider from "@/common/components/CompaniesFilter/components/CompaniesGridFilterContextProvider/CompaniesGridFilterContextProvider";
import {
  CompaniesDesktopLoader,
  CompaniesPhoneLoader,
} from "@/common/components/loaders/Companies";
import {
  excludedStatusesForHiredTab,
  excludedStatusesForPendingTab,
  excludedStatusesForRejectedTab,
} from "@/common/components/RecommendationStatus/Props";
import Jobs from "@/pages/public/Jobs";
import JobsFilterContextProvider from "@/pages/public/Jobs/components/JobsFilter/components/JobsFilterContextProvider";
import { isOnePage } from "@/config";

const CompanyVacancies = lazy(
  () => import("@/pages/company/CompanyDashboard/components/CompanyVacancies")
);
const MyCompany = lazy(() => import("@/pages/company/MyCompany"));
const CompanyRecommendations = lazy(
  () =>
    import("@/pages/company/CompanyDashboard/components/CompanyRecommendations")
);
const AdminVacancies = lazy(
  () => import("@/pages/admin/AdminDashboard/components/AdminVacancies")
);
const AdminRecommendations = lazy(
  () => import("@/pages/admin/AdminDashboard/components/AdminRecommendations")
);
const AdminCompanies = lazy(
  () => import("@/pages/admin/AdminDashboard/components/AdminCompanies")
);
const AdminReporting = lazy(
  () => import("@/pages/admin/AdminDashboard/components/AdminReporting")
);
const UnsubscribeFromEmailNotifications = lazy(
  () => import("@/pages/public/UnsubscribeFromEmailNotifications")
);
const UserVerification = lazy(() => import("@/pages/admin/UserVerification"));
const RelevanceChecker = lazy(() => import("@/pages/public/RelevanceChecker"));
const CvMaker = lazy(() => import("@/pages/public/CvMaker"));
const TaxCalculator = lazy(() => import("@/pages/public/TaxCalculator"));
const Companies = lazy(() => import("@/pages/public/Companies"));
const Vacancies = lazy(() => import("@/pages/public/Vacancies"));
const MyVacancies = lazy(() => import("@/pages/public/MyVacancies"));
const VacancyView = lazy(() => import("@/pages/public/VacancyView"));
const JobView = lazy(() => import("@/pages/public/JobView"));
const ReferralVacancyPage = lazy(
  () => import("@/common/components/ReferralVacancyPage")
);
const CandidateView = lazy(() => import("@/pages/public/CandidateView"));
const ReferralDashboard = lazy(
  () => import("@/pages/referral/ReferralDashboard")
);
const Candidates = lazy(() => import("@/pages/public/Candidates"));
const ChatRoomScreen = lazy(
  () => import("@/pages/chat/components/ChatRoomScreen")
);
const ChatsListScreen = lazy(
  () => import("@/pages/chat/components/ChatsListScreen")
);
const RecommendatorCandidates = lazy(
  () => import("@/pages/recommendator/RecommendatorCandidates")
);
const RecommendatorSignUp = lazy(
  () => import("@/pages/public/RecommenderSignUp")
);
const ResetPassword = lazy(() => import("@/pages/public/ResetPassword"));
const CompanySignUp = lazy(() => import("@/pages/public/CompanySignUp"));
const RecommendatorProfile = lazy(
  () => import("@/pages/recommendator/RecommendatorProfile")
);
const NotFound = lazy(() => import("@/pages/public/NotFound"));
const CompanyView = lazy(() => import("@/pages/public/CompanyView"));
const SalesDashboard = lazy(() => import("@/pages/sales/SalesDashboard"));
const Home = lazy(() => import("@/pages/public/Home"));
const RecommendatorDashboard = lazy(
  () => import("@/pages/recommendator/RecommendatorDashboard")
);
const RecommendatorRecommendations = lazy(
  () => import("@/pages/recommendator/RecommendatorRecommendations")
);
const Cpc = lazy(() => import("@/pages/public/Cpc"));
const CpcV2 = lazy(() => import("@/pages/public/CpcV2"));

const RouterSwitch: FC = () => {
  const isTablet = useIsTablet();
  const suspense = (children: ReactNode, fallback?: ReactNode) => (
    <Suspense fallback={fallback || <PageLoader />}>{children}</Suspense>
  );

  if (isOnePage) {
    return (
      <Switch>
        <Route
          exact
          path={getLocalitiesPaths(getRouteWithSlash(AppRoute.HOME, true))}>
          {suspense(
            <JobsFilterContextProvider>
              {suspense(
                <Jobs />,
                isTablet ? <VacanciesPhoneLoader /> : <VacanciesDesktopLoader />
              )}
            </JobsFilterContextProvider>
          )}
        </Route>
        <Redirect to={getRouteWithSlash(AppRoute.HOME)} />
      </Switch>
    );
  }

  return suspense(
    <Switch>
      <Route
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.HOME, true))}>
        {suspense(
          <Home />,
          isTablet ? <HomePhoneLoader /> : <HomeDesktopLoader />
        )}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.JOBS, true))}>
        {suspense(
          <JobsFilterContextProvider>
            {suspense(
              <Jobs />,
              isTablet ? <VacanciesPhoneLoader /> : <VacanciesDesktopLoader />
            )}
          </JobsFilterContextProvider>
        )}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.VACANCIES_LIST, true)
        )}>
        {suspense(
          <VacanciesFilterContextProvider>
            {suspense(
              <Vacancies />,
              isTablet ? <VacanciesPhoneLoader /> : <VacanciesDesktopLoader />
            )}
          </VacanciesFilterContextProvider>
        )}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANIES_LIST, true)
        )}>
        {suspense(
          <CompaniesGridFilterContextProvider>
            {suspense(
              <Companies />,
              isTablet ? <CompaniesPhoneLoader /> : <CompaniesDesktopLoader />
            )}
          </CompaniesGridFilterContextProvider>
        )}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RELEVANCE_CHECKER, true)
        )}>
        {suspense(<RelevanceChecker />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.CV_MAKER, true))}>
        {suspense(<CvMaker />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RELEVANCE_CHECKER_EMAIL, true)
        )}>
        <Redirect to={getRouteWithSlash(AppRoute.RELEVANCE_CHECKER)} />
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RELEVANCE_CHECKER_COMPANY, true)
        )}>
        <Redirect to={getRouteWithSlash(AppRoute.RELEVANCE_CHECKER)} />
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.TAX_CALCULATOR, true)
        )}>
        {suspense(<TaxCalculator />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANY_REGISTER, true)
        )}>
        {suspense(<CompanySignUp />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RESET_PASSWORD, true)
        )}>
        {suspense(<ResetPassword />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RECOMMENDATOR_SIGN_UP, true)
        )}>
        {suspense(<RecommendatorSignUp />)}
      </Route>
      <PrivateRoute
        roles={[Role.Candidate, Role.Recruiter]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.MY_VACANCIES_LIST, true)
        )}>
        {suspense(
          <VacanciesFilterContextProvider>
            {suspense(
              <MyVacancies />,
              isTablet ? <VacanciesPhoneLoader /> : <VacanciesDesktopLoader />
            )}
          </VacanciesFilterContextProvider>
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Candidate]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RECOMMENDATOR_DASHBOARD, true)
        )}>
        {suspense(<RecommendatorDashboard />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Company, Role.Sales, Role.Candidate]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.USER_PROFILE_SETTINGS, true)
        )}>
        {suspense(<RecommendatorProfile />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RECOMMENDATOR_CANDIDATES, true)
        )}>
        <CandidatesFilterContextProvider>
          <RecommendatorCandidates />
        </CandidatesFilterContextProvider>
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Candidate]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RECOMMENDATOR_RECOMMENDATIONS, true)
        )}>
        {suspense(
          <RecommendatorRecommendations
            excludedStatuses={excludedStatusesForPendingTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Candidate]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(
            AppRoute.RECOMMENDATOR_RECOMMENDATIONS_REJECTED,
            true
          )
        )}>
        {suspense(
          <RecommendatorRecommendations
            excludedStatuses={excludedStatusesForRejectedTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Candidate]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RECOMMENDATOR_RECOMMENDATIONS_HIRED, true)
        )}>
        {suspense(
          <RecommendatorRecommendations
            excludedStatuses={excludedStatusesForHiredTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Candidate]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.RECOMMENDATOR_RECOMMENDATIONS_ALL, true)
        )}>
        {suspense(<RecommendatorRecommendations />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Company]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANY_VACANCIES, true)
        )}>
        {suspense(<CompanyVacancies />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Company]}
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.MY_COMPANY, true))}>
        {suspense(<MyCompany />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Company]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANY_RECOMMENDATIONS_ALL, true)
        )}>
        {suspense(<CompanyRecommendations />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Company]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANY_RECOMMENDATIONS, true)
        )}>
        {suspense(
          <CompanyRecommendations
            excludedStatuses={excludedStatusesForPendingTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Company]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANY_RECOMMENDATIONS_REJECTED, true)
        )}>
        {suspense(
          <CompanyRecommendations
            excludedStatuses={excludedStatusesForRejectedTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Company]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANY_RECOMMENDATIONS_HIRED, true)
        )}>
        {suspense(
          <CompanyRecommendations
            excludedStatuses={excludedStatusesForHiredTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.ADMIN_RECOMMENDATIONS, true)
        )}>
        {suspense(
          <AdminRecommendations
            excludedStatuses={excludedStatusesForPendingTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.ADMIN_RECOMMENDATIONS_REJECTED, true)
        )}>
        {suspense(
          <AdminRecommendations
            excludedStatuses={excludedStatusesForRejectedTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.ADMIN_RECOMMENDATIONS_HIRED, true)
        )}>
        {suspense(
          <AdminRecommendations
            excludedStatuses={excludedStatusesForHiredTab}
          />
        )}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.ADMIN_RECOMMENDATIONS_ALL, true)
        )}>
        {suspense(<AdminRecommendations />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.ADMIN_VACANCIES, true)
        )}>
        {suspense(<AdminVacancies />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.ADMIN_COMPANIES, true)
        )}>
        {suspense(<AdminCompanies />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.ADMIN_REPORTING, true)
        )}>
        {suspense(<AdminReporting />)}
      </PrivateRoute>
      <Route
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.NOT_FOUND, true))}>
        {suspense(<NotFound />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.VACANCY_VIEW, true)
        )}>
        {suspense(<VacancyView />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.JOB_VIEW, true))}>
        {suspense(<JobView />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.CPC, true))}>
        {suspense(<Cpc />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.CPC_V2, true))}>
        {suspense(<CpcV2 />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.REFERRAL_VACANCY_VIEW, true)
        )}>
        {suspense(<ReferralVacancyPage />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.COMPANY_VIEW, true)
        )}>
        {suspense(<CompanyView />)}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.CANDIDATES_LIST, true)
        )}>
        {suspense(
          <CandidatesFilterContextProvider>
            {suspense(
              <Candidates />,
              isTablet ? <CandidatesPhoneLoader /> : <CandidatesDesktopLoader />
            )}
          </CandidatesFilterContextProvider>
        )}
      </Route>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.CANDIDATE_VIEW, true)
        )}>
        {suspense(<CandidateView />)}
      </Route>
      <PrivateRoute
        roles={[Role.Referral]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.REFERRAL_DASHBOARD, true)
        )}>
        {suspense(<ReferralDashboard />)}
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Company, Role.Admin, Role.Candidate]}
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.CHAT, true))}>
        <ChatsListScreen />
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Recruiter, Role.Company, Role.Admin, Role.Candidate]}
        exact
        path={getLocalitiesPaths(getRouteWithSlash(AppRoute.CHAT_VIEW, true))}>
        <ChatRoomScreen />
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Sales]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.SALES_DASHBOARD, true)
        )}>
        {suspense(<SalesDashboard />)}
      </PrivateRoute>
      <Route
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.UNSUBSCRIBE_EMAIL, true)
        )}>
        {suspense(<UnsubscribeFromEmailNotifications />)}
      </Route>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={getLocalitiesPaths(
          getRouteWithSlash(AppRoute.USER_VERIFICATION, true)
        )}>
        {suspense(<UserVerification />)}
      </PrivateRoute>
      <Redirect to={getRouteWithSlash(AppRoute.NOT_FOUND)} />
    </Switch>
  );
};

export default RouterSwitch;
